const contractABI = require('../contract-abi.json');

const contractAddress = "0xca0b4a2610a9838af5184304daed3e8410bad70d";
const alchemyKey = "https://eth-mainnet.alchemyapi.io/v2/-e5J0JE0735_JSnlYV9KRZdEy5bB-YdE";

const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);

export const connectWallet = async () => {
	if (window.ethereum) {
		try {
			const addressArray = await window.ethereum.request({
				method: "eth_requestAccounts",
			});
			const obj = {
				status: "",
				address: addressArray[0],
			};
			return obj;
		} catch (err) {
			return {
				address: "",
				status: err.message,
			};
		}
	} else {
		return {
			address: "",
			status: (
			  <p>
				<a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
				  Please install Metamask.
				</a>
			  </p>
			),
		};
	}
};

export const getCurrentWalletConnected = async() => {
	if (window.ethereum) {
		try {
			const addressArray = await window.ethereum.request({
				method: "eth_accounts",
			});
			if (addressArray.length > 0) {
				return {
					address: addressArray[0],
					status: "",
				};
			} else {
				return {
					address: "",
					status: "Please connect to metamask.",
				};
			}
		} catch (err) {
			return {
				address: "",
				status: err.message,
			};
		}
	} else {
		return {
			address: "",
			status: (
			  <p>
				<a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
				  Please install Metamask.
				</a>
			  </p>
            ),
        };
    }
};

export const mintNFT = async(address, amount) => {

	window.contract = await new web3.eth.Contract(contractABI, contractAddress);

	const transactionParameters = {
		to: contractAddress,
		from: window.ethereum.selectedAddress,
		'data': window.contract.methods.mint(window.ethereum.selectedAddress, amount).encodeABI(),
		// value: (666 * 10**14 * amount).toString(16)
	};
	

	try {
		const txHash = await window.ethereum
		.request({
				method: 'eth_sendTransaction',
				params: [transactionParameters],
			});
		return {
			success: true,
			status: ""
		}
	} catch (err) {
		return {
			success: false,
			status: err.message
		}
	}
}

export const getRemainingNFTs = async() => {
	window.contract = await new web3.eth.Contract(contractABI, contractAddress);
	const max_mintable = 6666;
	const minted = await window.contract.methods.totalSupply().call();
	return { max_mintable: max_mintable, minted: minted};
};

export const isPaused = async() => {
	window.contract = await new web3.eth.Contract(contractABI, contractAddress);
	const paused = await window.contract.methods._paused().call();
	return paused;
};
