import { useEffect, useState } from "react";
import {
	connectWallet,
	getCurrentWalletConnected,
	mintNFT,
	getRemainingNFTs,
	isPaused
} from "./utils/interact.js";

const Minter = (props) => {

  //State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [amount, setAmount] = useState();

  const [remaining, setRemaining] = useState("");
  const [total, setTotal] = useState("");
  const [paused, setPaused] = useState("");

 
  useEffect(async () => {
	  const {address, status} = await getCurrentWalletConnected();
	  setWallet(address);
	  setStatus(status);

	  const {max_mintable, minted} = await getRemainingNFTs();
	  setRemaining(max_mintable - minted);
	  setTotal(max_mintable);
	  
	  const is_paused = await isPaused();
	  setPaused(is_paused);
	  
	  addWalletListener();
  }, []);

  function addWalletListener() {
	  if (window.ethereum) {
		  window.ethereum.on("accountsChanged", (accounts) => {
			  if (accounts.length > 0) {
				  setWallet(accounts[0]);
			  } else {
				  setWallet("");
				  setStatus("Please connect to Metamask.");
			  }
		  });
	  } else {
		  setStatus(
			  <p>
				<a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
				  Please install Metamask.
				</a>
			  </p>
		);
	}
  }

  const connectWalletPressed = async () => {
	const walletResponse = await connectWallet();
	setStatus(walletResponse.status);
	setWallet(walletResponse.address);
  };

  const onMintPressed = async () => {
	const { status } = await mintNFT(walletAddress, amount);
	setStatus(status)
  };

  return (
 	  <ul class="navbar-nav mx-auto mb-2 mb-md-0">
        <li class="nav-item">
          <a href="index.html">
            <img src="assets/images/logosmall.png" alt="" />
          </a>
        </li>&nbsp;
        <li class="nav-item">

            { remaining > 0 ? ( paused === true ? (
				<>
				  <div class="mt-60 mb-40 pb-10 pt-10 fixed-top bg-light">
					<div class="container d-flex justify-content-center">
					  <p>Minting is paused.</p>
					</div>
				  </div>
				</>
			) : (
			<>
          <div class="d-flex">
			<button id="walletButton" class="btn btn-outline-success" onClick={connectWalletPressed}>
                {walletAddress.length > 0 ? (
                "Connected: " +
                String(walletAddress).substring(0, 6) +
                "..." +
                String(walletAddress).substring(38)
			  ) : (
			    "Connect"
			  )}
			</button>
            <input class="form-control ml-20 mr-20" type="number" placeholder="Amount to resurrect (max 20)" min="1" max="20" aria-label="Amount to mint" onChange={(event) => setAmount(event.target.value)}/>
            
            <button id="mintButton" class="btn btn-outline-success" onClick={onMintPressed}>Resurrect</button>
          </div>

          <div id="status">
            {status}
          </div>
          <div class="mt-60 mb-40 pb-10 pt-10 fixed-top bg-light">
            <div class="container d-flex justify-content-center">
              <code>{remaining}/{total} Available</code>
            </div>
          </div>
          </>
            )) : (
              <>
				<h5 id="sorry">Sorry, all frankenapes have been resurrected!</h5> &nbsp;
				<ul>
					<li>
						<a href="https://opensea.io/asset/frankenapes"><i class="fa fa-anchor"></i> View on OpenSea</a>
					</li>
				</ul>
              </>
            )}
        </li>
      </ul>
  );
};

export default Minter;
